import { useEffect, useState } from 'react';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import { getRedirectPathWithQuery } from '@activebrands/core-web/utils/query';
import { useLocation } from '@reach/router';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const CountrySelectWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    padding: '8px',

    [media.min['mobile.lg']]: {
        padding: '12px',
    },

    [media.min['desktop.md']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '24px',
        padding: '16px',
    },
});

const CountryContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [media.min['desktop.md']]: {
        gridColumn: '4 / span 6',
        alignSelf: 'center',
    },
});

const CountryButtonsWrapper = styled('ul', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px',

    [media.min['desktop.md']]: {
        gridColumn: '4 / span 6',
    },
});

const SectionWrapper = styled('div', {
    width: '100%',
    position: 'relative',
    zIndex: '2',
    paddingBottom: '64px',
});

const siteSelector = () => {
    const [css] = useStyletron();

    const {
        desktopBackground,
        mobileBackground,
        siteSelectorExternalHeading,
        siteSelectorExternalHeadingType,
        siteSelectorExternalLinks,
        siteSelectorExternalParagraph,
        siteSelectorPageHeading,
        siteSelectorPageHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
    } = useSiteSelectorQuery();

    const transformedBackground = transformFlexibleBackgrounds([mobileBackground, null, desktopBackground], 1);
    const backgroundOverlays = extractBackgroundOverlays([mobileBackground, null, desktopBackground]);

    const location = useLocation();
    const [pathWithQuery, setPathWithQuery] = useState('/');
    useEffect(() => {
        setPathWithQuery(getRedirectPathWithQuery(location));
    }, [location]);

    return (
        <>
            <BackgroundDynamic
                background={transformedBackground}
                backgroundOverlay={backgroundOverlays}
                loading="eager"
            />
            <div className={css({ paddingTop: '64px' })}>
                {siteSelectorLinks?.length > 0 ? (
                    <SectionWrapper>
                        <CountrySelectWrapper>
                            {(siteSelectorPageHeading || siteSelectorParagraph) && (
                                <CountryContent>
                                    {siteSelectorPageHeading && (
                                        <Heading as={siteSelectorPageHeadingType} fontKeys="Primary/32_110_-2">
                                            {siteSelectorPageHeading}
                                        </Heading>
                                    )}
                                    {siteSelectorParagraph && (
                                        <RichText data={siteSelectorParagraph} fontKeys="Secondary/16_130" />
                                    )}
                                </CountryContent>
                            )}
                            <CountryButtonsWrapper>
                                {siteSelectorLinks.map(site => (
                                    <ThemeButton
                                        $style={{ width: ['100%', null, 'calc(50% - 4px)'], height: '40px' }}
                                        as="a"
                                        href={`${site.path}${pathWithQuery}`}
                                        key={site.name}
                                        onClick={() => setSiteSelectorCookie(site.path)}
                                    >
                                        <div
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: 'auto',
                                                alignSelf: 'center',
                                                gridColumn: '1 / span 2',
                                            })}
                                        >
                                            {site.icon && (
                                                <div
                                                    className={css({
                                                        marginRight: '16px',
                                                        width: '24px',
                                                        height: '24px',
                                                    })}
                                                    src={site.icon}
                                                >
                                                    <Image
                                                        $style={{ borderRadius: '60px', overflow: 'hidden' }}
                                                        sizes={['24px']}
                                                        src={{ url: site?.icon, width: [24, 48, 72] }}
                                                    />
                                                </div>
                                            )}
                                            <Paragraph
                                                $style={{ textAlign: 'left' }}
                                                fontKeys="Miscellaneous/16_100_-1"
                                            >
                                                {site.label}
                                            </Paragraph>
                                        </div>
                                    </ThemeButton>
                                ))}
                            </CountryButtonsWrapper>
                        </CountrySelectWrapper>
                    </SectionWrapper>
                ) : null}
                {siteSelectorExternalLinks?.length > 0 ? (
                    <SectionWrapper>
                        <CountrySelectWrapper>
                            {(siteSelectorExternalHeading || siteSelectorExternalParagraph) && (
                                <CountryContent>
                                    {siteSelectorExternalHeading && (
                                        <Heading as={siteSelectorExternalHeadingType} fontKeys="Primary/32_110_-2">
                                            {siteSelectorExternalHeading}
                                        </Heading>
                                    )}
                                    {siteSelectorExternalParagraph && (
                                        <RichText data={siteSelectorExternalParagraph} fontKeys="Secondary/16_130" />
                                    )}
                                </CountryContent>
                            )}
                            <CountryButtonsWrapper>
                                {siteSelectorExternalLinks.map(site => (
                                    <ThemeButton
                                        $style={{ width: ['100%', null, 'calc(50% - 4px)'], height: '40px' }}
                                        as="a"
                                        href={`${site.path}${pathWithQuery}`}
                                        key={site.name}
                                    >
                                        <div
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: 'auto',
                                                alignSelf: 'center',
                                                gridColumn: '1 / span 2',
                                            })}
                                        >
                                            {site.icon && (
                                                <div
                                                    className={css({
                                                        marginRight: '16px',
                                                        width: '24px',
                                                        height: '24px',
                                                    })}
                                                    src={site.icon}
                                                >
                                                    <Image
                                                        $style={{ borderRadius: '60px', overflow: 'hidden' }}
                                                        sizes={['24px']}
                                                        src={{ url: site?.icon, width: [24, 48, 72] }}
                                                    />
                                                </div>
                                            )}
                                            <Paragraph
                                                $style={{ textAlign: 'left' }}
                                                fontKeys="Miscellaneous/16_100_-1"
                                            >
                                                {site.label}
                                            </Paragraph>
                                        </div>
                                    </ThemeButton>
                                ))}
                            </CountryButtonsWrapper>
                        </CountrySelectWrapper>
                    </SectionWrapper>
                ) : null}
            </div>
        </>
    );
};

export default siteSelector;
